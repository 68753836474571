<template>
<div class="card card-default">
    <div class="card-header">
        <h3 class="card-title">Maombi ya mkopo</h3>
    </div>
    <div class="card-body">
        <form @submit.prevent="submitApplication()" method="post" id="submitform">
            <div class="row">
                <div class="col-12">
                    <label for="">Tumeshawahi kukukopesha ? <i style="color:red;">*</i></label>
                    <select name="first_time_application" class="form-control" v-model="first_time_application" required>
                            <option value=""></option>
                            <option value="Yes">Ndiyo</option>
                            <option value="No">Hapana</option>
                    </select>
                </div>
            </div>
            <div v-if="first_time_application != ''">
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="loan_amount">Kiasi cha mkopo unahitaji(Tshs)<i style="color:red;">*</i></label>
                        <vue-number v-model="loan_amount" v-bind="number" class="form-control" required placeholder="50,000"></vue-number>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="loan_period_mode">Muda wa mkopo<i style="color:red;">*</i> </label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select name="loan_period_mode" class="form-control btn btn-primary" v-model="loan_period_mode" required>
                                    <option value="Week">Wiki</option>
                                    <option value="Month">Mwezi</option>
                                </select>
                            </div>
                            <select name="loan_period" v-model="loan_period" class="form-control rounded-0" required >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div> 
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="repayment_mode">Kiasi cha marejesho(Tshs)<i style="color:red;">*</i> </label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select name="repayment_mode" class="form-control btn btn-primary" v-model="repayment_mode" required>
                                    <option value="Weekly">Kwa Wiki</option>
                                    <option value="Monthly">Kwa Mwezi</option>
                                </select>
                            </div>
                            <vue-number v-model="repayment_amount" v-bind="number" class="form-control rounded-0" required placeholder="50,000"></vue-number>
                        </div> 
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="loan_product">Aina ya Mkopo/Loan Product<i style="color:red;">*</i> </label>
                        <select class="form-control" name="loan_product" v-model="loan_product" required>
                            <option></option>
                            <option value="Business Group Loan">Mkopo wa Biashara kwa Vikundi</option>
                            <option value="Business Individual Loan">Mkopo wa Biashara kwa Mtu</option>
                            <option value="Salary Based Loan">Mkopo wa Dharura kwa Waajiriwa</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="loan_product == 'Salary Based Loan'">
                        <label for="school_employer_name">Mwajiri wako<i style="color:red;">*</i> </label>
                        <select class="form-control" name="school_employer_name" v-model="school_employer_name" required>
                            <option></option>
                            <option v-for="(school, index) in registered_schools" :key="index" :value="school">{{ school }}</option>
                        </select>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="loan_purpose">Dhumuni la Mkopo<i style="color:red;">*</i> </label>
                        <select class="form-control" name="loan_purpose" v-model="loan_purpose" required>
                            <option></option>
                            <option value="Capital Raise">Kukuza Mtaji wa Biashara</option>
                            <option value="School Fees">Ada ya shule</option>n
                            <option value="Medical">Matibabu</option>
                            <option value="Agriculture">Kilimo</option>
                            <option value="Building">Ujenzi</option>
                            <option value="Investment">Uwekezaji</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2" v-if="loan_product != 'Salary Based Loan' && loan_product !=''">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <label for="collateral">Dhamana <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="collateral" v-model="collateral" required />
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="residence">Makazi <i style="color:red;">*</i></label>
                        <select class="form-control" name="residence" v-model="residence" required>
                            <option></option>
                            <option value="Rent House">Nimepanga</option>
                            <option value="Own House">Nimejenga</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="phone_number">Namba yako ya simu <i style="color:red;">*</i></label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button type="button" class="btn btn-primary"><span class="fa fa-phone"></span></button>
                            </div>
                            <input type="text" class="form-control rounded-0" name="phone_number" v-model="phone_number" required pattern="^[0]{1}[1-9]{1}[0-9]{8}$">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="first_time_application == 'No'">
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="first_name">Jina la kwanza <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="first_name" pattern="^[a-zA-Z]{1}[a-zA-Z0-9']{1,100}$" v-model="first_name" required />
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="middle_name">Jina la kati</label>
                        <input type="text" class="form-control rounded-0" name="middle_name" pattern="^[a-zA-Z]{1}[a-zA-Z0-9']{1,100}$" v-model="middle_name" />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="surname">Jina la mwisho/Ukoo <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="surname" pattern="^[a-zA-Z]{1}[a-zA-Z0-9']{1,100}$" v-model="surname" required/>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="gender">Jinsia <i style="color:red;">*</i></label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button type="button" class="btn btn-primary"><i class="fa-solid fa fa-venus-double"></i></button>
                            </div>
                            <select class="form-control" name="gender" v-model="gender" required>
                                <option value=""></option>
                                <option value="Male">Mwanaume</option>
                                <option value="Female">Mwanamke</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                            <label for="date_of_birth">Tarehe ya kuzaliwa <i style="color:red;">*</i></label>
                            <VueDatePicker v-model="date_of_birth" :format="'yyy-MM-dd'" :max-date="'2000-01-01'" required/>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="number_of_dependants">Idadi ya wategemezi<i style="color:red;">*</i> </label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button type="button" class="btn btn-primary"><span class="fa fa-users"></span></button>
                            </div>
                            <input type="number" class="form-control rounded-0" name="number_of_dependants" min="0" step="1" v-model="number_of_dependants" required/>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="education_level">Kiwango cha elimu <i style="color:red;">*</i></label>
                        <select class="form-control rounded-0" name="education_level" v-model="education_level" required>
                            <option value=""></option>
                            <option value="Primary">Shule ya msingi</option>
                            <option value="Secondary">Kidato cha Nne</option>
                            <option value="High School">Kidato cha Sita</option>
                            <option value="College">Chuo</option>
                            <option value="University">Chuo Kikuu</option>
                            <option value="Unschooled">Sijasoma</option>
                        </select>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="marital_status">Hali ya ndoa <i style="color:red;">*</i></label>
                        <select class="form-control rounded-0" name="marital_status" v-model="marital_status" required>
                            <option value=""></option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widowed">Widowed</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="next_of_kin_name">Mtu wako wa karibu</label>
                        <input type="text" class="form-control rounded-0" name="next_of_kin_name" pattern="^[a-zA-Z]{1}[a-zA-Z0-9'\t\s]{1,100}$" v-model="next_of_kin_name"/>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="next_of_kin_relationship">Uhusiano wa mtu wako wa karibu</label>
                        <select name="next_of_kin_relationship" class="form-control rounded-0" v-model="next_of_kin_relationship">
                            <option value=""></option>
                            <option value="Spause">Mwenza</option>
                            <option value="Child">Mtoto</option>
                            <option value="Relative">Ndugu</option>
                            <option value="Neighbour">Jirani</option>
                            <option value="Co-Worker">Mafanyakazi mwenza</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="next_of_kin_phone_number">Namba ya simu ya mtu wa karibu</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button type="button" class="btn btn-primary"><span class="fa fa-phone"></span></button>
                            </div>
                            <input type="text" class="form-control rounded-0" name="next_of_kin_phone_number" pattern="^[0]{1}[1-9]{1}[0-9]{8}$" v-model="next_of_kin_phone_number"/>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="employment_type">Hali ya ajira <i style="color:red;">*</i></label>
                        <select name="employment_state" class="form-control rounded-0" v-model="employment_state" @change="checkEmployment()" required>
                            <option value=""></option>
                            <option value="Self Employed">Nimejiari</option>
                            <option value="Employed">Nimeajiriwa</option>
                            <option value="Unemployed">Sina ajira</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2" v-if="employed">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="name_of_employer">Taasisi uliyoajiriwa <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="name_of_employer" pattern="[a-zA-Z0-9_'-]{255}" v-model="name_of_employer" required/>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="possess_business">Unafanya biashara? <i style="color:red;">*</i></label>
                        <select class="form-control rounded-0" name="possess_business" v-model="possess_business" @change="checkBusiness" required>
                            <option value=""></option>
                            <option value="Yes">Ndiyo</option>
                            <option value="No">Hapana</option>
                        </select>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="do_business">
                        <label for="business_type">Biashara unayofanya <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="business_type" pattern="[a-zA-Z0-9_'-]{255}" v-model="business_type" required/>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="type_of_identification">Namba ya kitambulisho<i style="color:red;">*</i></label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select name="type_of_identification" class="form-control btn btn-primary"  v-model="type_of_identification" required>
                                    <option value="National ID">National ID/NIDA</option>
                                    <option value="Passport">Passport</option>
                                    <option value="Driving License">Driving License</option>
                                    <option value="Voting ID">Voting ID</option>
                                    <option value="Employment ID">Employment ID</option>
                                </select>
                            </div>
                            <input type="text" class="form-control rounded-0" name="identification_number" v-model="identification_number" required placeholder="eg. 123-378-HT"/>
                        </div>  
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="collateral">Dhamana <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="collateral" v-model="collateral" required />
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="residence">Makazi <i style="color:red;">*</i></label>
                        <select class="form-control" name="residence" v-model="residence" required>
                            <option></option>
                            <option value="Rent House">Nimepanga</option>
                            <option value="Own House">Nimejenga</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="region">Mkoa <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="region" v-model="region" required />
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="district">Wilaya <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="district" v-model="district" required />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="ward">kata <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="ward" v-model="ward" required />
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="street">Mtaa <i style="color:red;">*</i></label>
                        <input type="text" class="form-control rounded-0" name="street" v-model="street" required />
                    </div>
                </div>
            </div>
            <div v-if="first_time_application != ''">
                <!-- First Checkbox: Accept Credit Reference Bureau -->
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" name="accept_credit_reference_bureau" :value="true" v-model="accept_credit_reference_bureau" />
                            <label class="form-check-label" for="exampleCheck1">Nakubali taarifa zangu kuwasilishwa kwenye Taasisi za Rejea Mikopo (Credit Reference Bureaus) <i style="color:red;">*</i></label>
                        </div>
                    </div>
                </div>
                <!-- Second Checkbox: Accept Application Rejection -->
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck2" name="accept_appication_rejection" :value="true" v-model="accept_appication_rejection" />
                            <label class="form-check-label" for="exampleCheck2">Nakubali ombi langu kukataliwa kama sitokidhi vigezo <i style="color:red;">*</i></label>
                        </div>
                    </div>
                </div>
                <!-- Button Group -->
                <div class="row mt-2">
                    <div class="col-12">
                        <div class="btn-group" role="group" aria-label="Action">
                            <router-link to="/home" class="btn btn-danger btn-sm"> Cancel <i class="fa fa-times-circle"></i> </router-link>
                            <button type="reset" class="btn btn-secondary btn-sm">Clear <i class="fa fa-trash"></i></button>
                            <button type="submit" v-if="accept_credit_reference_bureau && accept_appication_rejection" class="btn btn-primary btn-sm">Submit <i class="fa fa-arrow-circle-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>
<script>
    //import axios from "axios";
    import DOMPurify from 'dompurify';
    export default {
        name: "LoanApplication",
        data(){
            return {
                loan_amount: "",
                loan_period_mode: "",
                loan_period: "",
                repayment_mode: "",
                repayment_amount: "",
                loan_purpose: "",
                first_name: "",
                middle_name: "",
                surname: "",
                gender: "",
                date_of_birth: "",
                number_of_dependants: "",
                education_level: "",
                marital_status: "",
                next_of_kin_name: "",
                next_of_kin_relationship: "",
                next_of_kin_phone_number: "",
                employment_state: "",
                name_of_employer: "",
                possess_business: "",
                business_type: "",
                type_of_identification: "",
                identification_number: "",
                phone_number: "",
                region: "",
                district: "",
                ward: "",
                street: "",
                accept_credit_reference_bureau: false,
                accept_appication_rejection:false,
                employed:false,
                do_business:false,
                collateral:"",
                residence:"",
                first_time_application:"",
                loan_product:"",
                registered_schools:['Father Mponjoli Pre & Primary School'],
                school_employer_name:""
                
            }
        },
        methods:{
            submitApplication(){
                var request_data = {
                    'loan_amount': DOMPurify.sanitize(this.loan_amount),
                    'loan_period_mode':DOMPurify.sanitize(this.loan_period_mode),
                    'loan_period': DOMPurify.sanitize(this.loan_period),
                    'repayment_mode': DOMPurify.sanitize(this.repayment_mode),
                    'repayment_amount': DOMPurify.sanitize(this.repayment_amount),
                    'loan_purpose': DOMPurify.sanitize(this.loan_purpose),
                    'first_name': DOMPurify.sanitize(this.first_name),
                    'middle_name': DOMPurify.sanitize(this.middle_name),
                    'surname': DOMPurify.sanitize(this.surname),
                    'gender': DOMPurify.sanitize(this.gender),
                    'date_of_birth': DOMPurify.sanitize(this.date_of_birth),
                    'number_of_dependants': DOMPurify.sanitize(this.number_of_dependants),
                    'education_level': DOMPurify.sanitize(this.education_level),
                    'marital_status': DOMPurify.sanitize(this.marital_status),
                    'next_of_kin_name': DOMPurify.sanitize(this.next_of_kin_name),
                    'next_of_kin_relationship': DOMPurify.sanitize(this.next_of_kin_relationship),
                    'next_of_kin_phone_number': DOMPurify.sanitize(this.next_of_kin_phone_number),
                    'employment_state': DOMPurify.sanitize(this.employment_state),
                    'name_of_employer': DOMPurify.sanitize(this.name_of_employer),
                    'possess_business': DOMPurify.sanitize(this.possess_business),
                    'business_type': DOMPurify.sanitize(this.business_type),
                    'type_of_identification': DOMPurify.sanitize(this.type_of_identification),
                    'identification_number': DOMPurify.sanitize(this.identification_number),
                    'phone_number': DOMPurify.sanitize(this.phone_number),
                    'region': DOMPurify.sanitize(this.region),
                    'district': DOMPurify.sanitize(this.district),
                    'ward': DOMPurify.sanitize(this.ward),
                    'street': DOMPurify.sanitize(this.street),
                    'collateral':DOMPurify.sanitize(this.collateral),
                    'residence':DOMPurify.sanitize(this.residence),
                    'first_time_application':DOMPurify.sanitize(this.first_time_application),
                    'loan_product':DOMPurify.sanitize(this.loan_product),
                    'school_employer_name':DOMPurify.sanitize(this.school_employer_name)
                };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(request_data)
                };
                this.$swal({
                    text: 'Tafadhali subiri ...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false,
                    showConfirmButton: false,
                    onOpen: ()=>{
                                this.$swal.showLoading();
                            } 
                    });
                    fetch("https://mis.lupab.co.tz/apply_loan", requestOptions).then(response => {
                        // Check if the request was successful
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        // Parse the response as JSON
                        return response.json();
                    }).then(data => {
                        // Handle the JSON data
                        if(data.code == 200){
                            document.getElementById("submitform").reset();
                            this.$swal({
                                title:"Status",
                                icon:"success",
                                text:DOMPurify.sanitize(data.message),
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                        }else{
                            this.$swal({
                                title:"Status",
                                icon:"error",
                                text:DOMPurify.sanitize(data.message)
                            });
                        }
                    }).catch(error => {
                        this.$swal({
                                title:"Status",
                                icon:"error",
                                text:"Exception occured "+DOMPurify.sanitize(error)
                        });
                    });
      
            },
            //checkEmployment method
            checkEmployment(){
                if(this.employment_state == 'Employed'){
                   this.employed = true; 
                }else{
                    this.employed = false; 
                }  
            },
            //checkBusiness
            checkBusiness(){
                if(this.possess_business == 'Yes'){
                   this.do_business = true; 
                }else{
                    this.do_business = false; 
                }  
            },
            getRegisteredSchools(){
                // const requestOptions = {
                //         method: "POST",
                //         mode: "cors",
                //         headers: {
                //             'Content-Type': 'application/json;charset=utf-8',
                //             'Access-Control-Allow-Origin':'*'
                //         }
                        //body: JSON.stringify(request_data)
                //};
                // fetch("https://mis.lupab.co.tz/get-schools", requestOptions).then(response => {
                //         // Check if the request was successful
                //         if (!response.ok) {
                //             throw new Error('Network response was not ok');
                //         }
                //         // Parse the response as JSON
                //         return response.json();
                //     }).then(data => {
                //         // Handle the JSON data
                //         if(data.code == 200){
                          
                //         }else{
                        
                //         }
                //     }).catch(error => {
                //         // Handle any errors that occurred during the fetch
                //         console.log(error);
                //     });
            }
        }
    }
</script>